<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        접속제한관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-icon
              class="question"
              small
              color="warning"
              v-on="on"
              v-bind="attrs"
            >mdi-help-circle-outline</v-icon>
          </template>
          <span>
            등록되지 않은 IP로의 접속을 차단합니다.
            <br>
            ※ IP를 등록하지 않을 경우 모든 IP의 접속을 허용합니다.
          </span>
        </v-tooltip>
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              @click="getList"
              color="primary"
              :loading="loading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        @click="dialog.show = true"
      >
        접속허용IP등록
      </v-btn>
    </div>
    <v-progress-linear
      v-if="loading === true"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-simple-table class="mt-10" v-else-if="loading === false && list.length > 0">
      <template #default>
        <tbody>
          <tr
            v-for="item in list"
            :key="item.no"
          >
            <td>
              {{ item.name }} / {{ item.ip }}
            </td>
            <td class="text-right">
              <v-btn
                small
                depressed
                color="error"
                class="ml-3 pr-3"
                @click="deleteIP(item.ip)"
              >
                삭제
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="empty-msg"
      v-else-if="loading === false && list.length === 0"
    >등록된 IP가 없습니다. <br/>※ IP를 등록하지 않을 경우 모든 IP의 접속을 허용합니다.</div>
    <v-dialog
      v-model="dialog.show"
      scrollable
      max-width="500"
      @click:outside="init"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">IP등록</span>
        </v-card-title>
        <v-card-text class="pb-0 content-wrap">
          <div class="content-box">
            <div class="section-wrap">
              <div class="section">
                <p class="tit">이름</p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="dialog.name"
                    outlined
                    dense
                    flat
                    label="1~15자"
                    autofocus
                  />
                </div>
              </div>
              <div class="section">
                <p class="tit">
                  IP주소
                  <v-btn
                    class="load-btn pr-2 pl-2"
                    small
                    color="info"
                    depressed
                    :loading="ipLoading"
                    @click="loadIP"
                  >
                    현재 IP 불러오기
                  </v-btn>
                </p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="dialog.ip"
                    outlined
                    dense
                    flat
                    label="XXX.XXX.XXX.*"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="registIP"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';

export default {
  name: 'Ip',
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    list: [],
    ipLoading: false,
    dialog: {
      show: false,
      name: '',
      ip: '',
    },
    loading: false,
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.dialog.show = false;
      this.dialog.name = '';
      this.dialog.ip = '';
      this.ipLoading = false;
    },
    registIP() {
      const { name, ip } = this.dialog;
      const ipCheck = (str) => {
        const arr = str.split('.');
        if (
          (str.length > 13 || str.length < 7)
          || (str.match(/\./g)?.length !== 3)
          || (arr[arr.length - 1] !== '*')
        ) {
          return false;
        }
        arr.pop();
        const loopCheck = arr.map((row) => !Number.isNaN(Number(row)));
        if (loopCheck.indexOf(false) !== -1) {
          return false;
        }
        return true;
      };
      if (name.length > 15 || name.length <= 0) {
        this.alert(['error', 'IP 이름은 1~ 15자여야 합니다.']);
      } else if (ipCheck(ip) === false) {
        this.alert(['error', 'IP 주소 형식에 맞춰주세요. (예: XXX.XXX.XXX.*)']);
      } else {
        this.$socket.emit('configs.ip.add', {
          name,
          ip,
        }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', 'IP가 등록되었습니다.']);
            this.init();
            this.getList();
          } else if (resp.name === 'DUPLICATE_IP') {
            this.alert(['error', '이미 등록된 IP 입니다. 다른 IP를 입력해주세요.']);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      }
    },
    getList() {
      this.loading = true;
      this.$socket.emit('configs.get', {
        key: 'ip',
      }, (resp) => {
        if (resp.result === 'success') {
          const list = resp.value.value == null ? [] : resp.value.value;
          this.list = list;
          this.loading = false;
        } else {
          this.confirm({ show: false });
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    deleteIP(ip) {
      this.$socket.emit('configs.ip.delete', {
        ip,
      }, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', 'IP가 삭제되었습니다.']);
          this.init();
          this.getList();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    async loadIP() {
      this.ipLoading = true;
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const ip = response.data.ip.split('.');
        ip[ip.length - 1] = '*';
        this.dialog.ip = ip.join('.');
        this.ipLoading = false;
      } catch (error) {
        this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
        this.ipLoading = false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getList();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.text-input {
  background-color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-top:2px;
  width: 280px;
  display: flex;
  align-items: center;
}
.empty-msg {
  padding: 20px 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
}
.content-wrap {
  .text-input-wrap {
    width: 240px;
    margin-bottom: -10px;
    margin-top: 10px;
  }
  .load-btn {
    margin-bottom: 5px;
  }
  .content-box {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    .pay-list {
      .pay-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-top:0px;
        .pay-info {
          display: flex;
          align-items: center;
          .pay-text {
            color: #262626;
          }
        }
      }
    }
    .section {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      color: #333;
      justify-content: space-between;
      & + .section, & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .pay-form {
        padding: 10px;
        min-width: 500px;
        max-width: 500px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top:-30px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .search-picker {
        width: 250px;
        max-width: 250px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top:8px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .room-wrap {
        max-height: 300px;
        overflow-y: auto;
        max-width: 320px;
      }
    }
    .section-wrap {
      & + .section, & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .section + .section {
        border-top: 1px dashed #ccc;
      }
    }
    .tit {
      font-size: .9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
  }
}
</style>
